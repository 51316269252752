import React, {useState} from "react";


function PaginationComponent(props) {
    const [newCurrentPage, setNewCurrentPage] = useState(props.currentPage);

    function goToPreviousPage() {
        setNewCurrentPage(newCurrentPage - 1);
        props.onPageChange(newCurrentPage - 1);
    }

    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        setNewCurrentPage(pageNumber);
        props.onPageChange(pageNumber);
    }

    function goToNextPage() {
        setNewCurrentPage(newCurrentPage + 1);
        props.onPageChange(newCurrentPage + 1);
    }

    const getPaginationGroup = (limitPages, totalPages) => {
        if (totalPages <= limitPages) {
            return new Array(totalPages).fill().map((_, idx) => idx + 1);
        }
        let start = Math.floor((newCurrentPage- 1) / limitPages) * limitPages;
        return new Array(limitPages).fill().map((_, idx) => start + idx + 1);
    };


    return (
        <div>
            <nav aria-label="..." style={{marginTop: 30}}>

                <ul className="pagination pagination-circle justify-content-center">
                    <li className={`page-item ${newCurrentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={goToPreviousPage}>Atrás</button>
                    </li>

                    {getPaginationGroup(5, props.totalPages).map((item, index) => (
                        <li key={index} className={`page-item ${newCurrentPage === item ? 'active' : ''}`}>
                            <button className="page-link" onClick={changePage}>{item}</button>
                        </li>
                    ))}

                    <li className={`page-item ${newCurrentPage === props.totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={goToNextPage}>Siguiente</button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};
export default PaginationComponent;