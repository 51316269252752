import Axios from "../config/axiosConfig";
import {api_url} from "../config/GlobalData";

export const GetTokenApi = (data) => {
    return Axios.instance.post(`${api_url}/api/v1/users/token/`, data);
}

export const GetTokenRefreshApi = (data) => {
    return Axios.instance.post(`${api_url}/api/v1/users/token/refresh/`, data);
}

export const GetTokenVerifyApi = (data) => {
    return Axios.instance.post(`${api_url}/api/v1/users/token/verify/`, data);
}