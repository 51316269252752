// Envs

export const debug = process.env.REACT_APP_DEBUG;
export const api_url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://127.0.0.1:8000";
export const api_username = process.env.REACT_APP_USERNAME;
export const api_password = process.env.REACT_APP_PASSWORD;
export const api_media_url = process.env.REACT_APP_API_MEDIA_URL;
export const web_url = process.env.REACT_APP_WEB_URL;
export const ga_tracking_id = process.env.REACT_APP_GA_TRACKING_ID;
export const client_id = process.env.REACT_APP_OSV_CLIENT_ID;
export const client_secret = process.env.REACT_APP_OSV_CLIENT_SECRET;