import React, {useEffect, useState} from "react";
import SectionComponent from "../../../shared/components/sections/SectionComponent";
import BreadCrumbComponent from "../../../shared/components/breadcrumb/BreadCrumbComponent";
import ListCardsComponent from "../../../shared/components/lists/ListCardsComponent";
import {GetAboutApi} from "../apis/AboutApi";
import TeamMembersComponent from "../../../shared/components/team/TeamMembersComponent";

export default function AboutView() {
    const [abouts, setAbouts] = useState([]);
    useEffect(() => {
        GetAboutApi()
            .then(response => {
                setAbouts(response.data)
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    const sections = [
        {
            name: 'Inicio',
            slug: '/',
            active: false
        },
        {
            name: 'Acerca de nosotros',
            slug: '/nosotros',
            active: true
        },
    ];

    return (
        <div className={"container"}>
            <SectionComponent title={"Acerca del Observatorio"} >
                <BreadCrumbComponent sections={sections}/>
                <ListCardsComponent cardsObjects={abouts} color={"#f0faff"}/>
                <TeamMembersComponent/>
            </SectionComponent>
        </div>
    );
}