import React from "react";


const BreadCrumbComponent = (props) => {
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {
                    props.sections.map((section, index) => (
                        <li key={index} className={section.active ? "breadcrumb-item active" : "breadcrumb-item"}
                            aria-current="page"><a href={section.slug}>{section.name}</a></li>
                    ))
                }
            </ol>
        </nav>
    );
};
export default BreadCrumbComponent;