import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SectionComponent from "../../../shared/components/sections/SectionComponent";
import BreadCrumbComponent from "../../../shared/components/breadcrumb/BreadCrumbComponent";
import ImageComponent from "../../../shared/components/image/ImageComponent";
import {GetPageApi} from "../apis/PagesApi";

export default function PageView() {
    let params = useParams();
    const [page, setPage] = useState({});
    useEffect(() => {
        GetPageApi(params['pageSlug'])
            .then(response => {
                setPage(response.data)
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    const sections = [
        {
            name: 'Inicio',
            slug: '/',
            active: false
        },
        {
            name: 'Páginas',
            slug: '#',
            active: false
        },
        {
            name: page.name,
            slug: page.slug,
            active: true
        },
    ];

    return (
        <div className={"container"}>
            {
                <SectionComponent title={page.name}>
                    <BreadCrumbComponent sections={sections}/>
                    <ImageComponent image={page.banner}/>
                    <div dangerouslySetInnerHTML={{__html: page.content}}/>
                </SectionComponent>
            }

        </div>
    );
}