// Axios
import Axios from "../../../config/axiosConfig";

// Globals
import {api_url} from "../../../config/GlobalData";

export const GetContactCategories = () => {

    return Axios.instance.get(`${api_url}/api/v1/portal/contact/categories/`);

}

export const PostContactRequest = (data) => {

    return Axios.instance.post(`${api_url}/api/v1/portal/contact-requests/`,
        data);

}
