import React from "react";
import SectionComponent from "../../../shared/components/sections/SectionComponent";
import BreadCrumbComponent from "../../../shared/components/breadcrumb/BreadCrumbComponent";
import SimpleMap from "../../../shared/components/maps/SimpleMapComponent";
import ContactFormComponent from "../../../shared/components/forms/ContactFormComponent";

export default function ContactView() {

    const sections = [
        {
            name: 'Inicio',
            slug: '/',
            active: false
        },
        {
            name: 'Contáctenos',
            slug: '/contacto',
            active: true
        },
    ]

    return (
        <div className={"container"}>
            <SectionComponent title={"Contáctenos"}>
                <BreadCrumbComponent sections={sections}/>
                <ContactFormComponent/>
            </SectionComponent>
            <SectionComponent>
                <SimpleMap/>
            </SectionComponent>
        </div>
    );
}