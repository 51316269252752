import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SectionComponent from "../../../shared/components/sections/SectionComponent";
import BreadCrumbComponent from "../../../shared/components/breadcrumb/BreadCrumbComponent";
import ImageComponent from "../../../shared/components/image/ImageComponent";
import {GetPostApi} from "../apis/PostsApi";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";

export default function PostView() {
    let params = useParams();
    const [post, setPost] = useState({});
    useEffect(() => {
        GetPostApi(params['postSlug'])
            .then(response => {
                setPost(response.data)
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    const sections = [
        {
            name: 'Inicio',
            slug: '/',
            active: false
        },
        {
            name: 'Publicaciones',
            slug: '/publicaciones',
            active: false
        },
        {
            name: post.title,
            slug: post.slug,
            active: true
        },
    ];

    return (
        <React.Fragment>
            {
                <div className={"container"}>
                    <SectionComponent title={post.title}>
                        <BreadCrumbComponent sections={sections}/>
                        <h4>{post.category ? post.category.name : "Categoria"}</h4>
                        <p>Fecha de publicación: {post.created_at}</p>
                        <ImageComponent image={post.banner}/>
                    </SectionComponent>
                    <SectionComponent>
                        <div dangerouslySetInnerHTML={{__html: post.content}}/>
                        <br/>
                        {
                            post.file ?
                                <ButtonComponent className={"btn btn-success"} title={"Descargar Archivo"}
                                                 url={post.file} target={"_blank"}/> : ""
                        }
                    </SectionComponent>
                </div>
            }
        </React.Fragment>
    );
}