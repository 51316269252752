import React from "react";
import CardHorizontalComponent from "../cards/CardHorizontalComponent";

const ListCardsComponent = (props) => {
    return (
        <div className="row ">
            {props.cardsObjects.map((card) => (
                <div key={card.id} className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                    <CardHorizontalComponent
                        color={props.color}
                        image={card.image}
                        title={card.title}
                        description={card.description}/>
                </div>
            ))}
        </div>
    );
};
export default ListCardsComponent;