// Axios
import Axios from "../../../config/axiosConfig";

// Globals
import {api_url} from "../../../config/GlobalData";


export const GetAboutApi = () => {

    return Axios.instance.get(`${api_url}/api/v1/portal/about/?active=true`);

}

export const GetTeamMembersApi = () => {

    return Axios.instance.get(`${api_url}/api/v1/portal/team-members/?active=true`);

}
