import React from "react";
import NotFoundImage from '../../../assets/images/error404.png'

const NotFoundComponent = (props) => {
    return (
        <div id="main_not_found">
            <div className="fof">
                <h1 align={"center"}>Error 404</h1>
                <center><img src={NotFoundImage}/></center>
            </div>
        </div>
    );
};
export default NotFoundComponent;