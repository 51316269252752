import React, {useEffect, useState} from "react";
import {GetMenusApi} from "../../../app/home/apis/HomeApi";
import {GetContactCategories, PostContactRequest} from "../../../app/contact/apis/ContactApi";

export default function ContactFormComponent() {
    const [menus, setMenus] = useState([]);
    const [contactCategories, setContactCategories] = useState([]);

    useEffect(() => {
        GetMenusApi("contact")
            .then(response => {
                setMenus(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }, []);


    useEffect(() => {
        GetContactCategories()
            .then(response => {
                setContactCategories(response.data.categories);
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    const handleSubmit = event => {
        event.preventDefault();

        const contactRequest = {
            "category": event.target['contact-category'].value,
            "full_name": event.target['full-name'].value,
            "email": event.target['email'].value,
            "phone": event.target['phone'].value,
            "message": event.target['message'].value,
            "active": true
        };

        PostContactRequest(contactRequest)
            .then(response => {
                event.target['contact-category'].value = "";
                event.target['full-name'].value = "";
                event.target['email'].value = "";
                event.target['phone'].value = "";
                event.target['message'].value = "";
                alert("Se ha enviado su mensaje con exito!");
            })
            .catch(error => {
                console.log(error);
                alert("Ha ocurrido un error, por favor intente nuevamente");
            })
    }

    return (

        <div className="card mb-3" style={{backgroundColor: "#fafeff"}}>
            <div className="row g-0">
                <div className="col-md-7">
                    <div className="col-md-12" style={{paddingLeft: 30}}>
                        <div className="card border border-info shadow-0 mb-3">
                            <div className="card-header">Envianos un mensaje</div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="input-group input-group-lg mb-3">
                                        <select required id="contact-category" style={{width: "100%"}}>
                                            {contactCategories.map((element) => (
                                                <option key={element[0]} value={element[0]}>{element[1]}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="input-group input-group-lg mb-3">
                                        <input
                                            name={"full-name"}
                                            type="text"
                                            className="form-control"
                                            placeholder="Nombre Completo"
                                            aria-label="Nombre Completo"
                                            aria-describedby="basic-addon1"
                                            required
                                        />
                                    </div>

                                    <div className="input-group input-group-lg mb-3">
                                        <input
                                            name={"email"}
                                            type="email"
                                            className="form-control"
                                            placeholder="Correo electrónico"
                                            aria-label="Correo electrónico"
                                            aria-describedby="basic-addon1"
                                            required
                                        />
                                    </div>

                                    <div className="input-group input-group-lg mb-3">
                                        <input
                                            name={"phone"}
                                            type="tel"
                                            className="form-control"
                                            placeholder="Teléfono"
                                            aria-label="Teléfono"
                                            aria-describedby="basic-addon1"
                                            required
                                        />
                                    </div>
                                    {/*<div className="input-group input-group-lg mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Asunto"
                                        aria-label="Asunto"
                                        aria-describedby="basic-addon1"
                                    />
                                </div>*/}
                                    <div className="input-group input-group-lg">
                                    <textarea
                                        name={"message"}
                                        style={{height: 100}}
                                        className="form-control"
                                        placeholder="Mensaje"
                                        required
                                        rows={10}/>
                                    </div>
                                    <br/>
                                    <div className="input-group input-group-lg mb-3 justify-content-center">
                                        <input className="btn btn-primary " type="submit" value="Enviar mensaje!"/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-5" style={{paddingLeft: 30}}>
                    <div className="card border border-info shadow-0 mb-3">
                        <div className="card-header">Información de Contácto</div>
                        <div className="card-body">
                            {menus.map((element) => (
                                <div key={element.id}>
                                    <h5 className="card-title">{element.name}</h5>
                                    <ul>
                                        {element.items_menu.map((element) => (
                                            <li key={element.id}><p className="card-text">{element.text}</p></li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                            <p>Observatorio de Salud de Villavicencio</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
        ;
}