import React from "react";

const CarouselComponent = (props) => {

    return (
        <div id="carouselExampleInterval" className="carousel slide" data-mdb-ride="carousel">
            <div className="carousel-indicators">
                {props.elements.map((element, index) => (
                    <button
                        key={element.id}
                        type="button"
                        data-mdb-target="#carouselExampleInterval"
                        data-mdb-slide-to={index}
                        className={index === 0 ? "active" : ""}
                        aria-current="true"
                        aria-label={element.title}
                    />
                ))}
            </div>

            <div className="carousel-inner">
                {props.elements.map((element, index) => (
                    <div  key={element.id} className={index === 0 ? "carousel-item active" : "carousel-item"} data-mdb-interval="3000">
                        <a href={element.url}>
                            <img
                                src={element.image ? element.image : "https://www.artntech.com.co/images-osv/banner1.jpg"}
                                className="d-block w-100 img-fluid"
                                height={"500px"}
                                alt={element.description}/>
                        </a>
                    </div>
                ))}
            </div>

            <button className="carousel-control-prev" data-mdb-target="#carouselExampleInterval" type="button"
                    data-mdb-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" data-mdb-target="#carouselExampleInterval" type="button"
                    data-mdb-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
};
export default CarouselComponent;