import React from "react";


const CardComponent = (props) => {
    return (
        <div className="card" style={{height: "500px", width: "100%"}}>
            <div className="bg-image hover-overlay ripple justify-content-center" data-mdb-ripple-color="light">
                <a href={props.url}>
                    <img align={"center"} src={props.image} style={{
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        height: "100%"
                    }} alt={props.description}/>
                </a>
            </div>
            <div className="d-grid">
                <button type="button" className="btn btn-info btn-sm">{props.category.name}</button>
            </div>
            <div className="card-body">
                <h4 className="card-title" align={"justify"}
                    style={{color: "#4adeff"}}>{props.title ? props.title.slice(0, 50) + "..." : "Título de la tarjeta"}</h4>
                <p className="card-text" align={"justify"} style={{color: "#707070"}}>
                    {props.description ? props.description.slice(0, 100) + "..." : ""}
                    <a href={props.url} className=""> Ver más</a>
                </p>
                <hr/>
                <p className="card-text" align={"left"}><b style={{color: "#4adeff"}}>Publicado
                    el:</b> {props.createdAt ? props.createdAt.slice(0, 10) : ""}</p>
            </div>
        </div>
    );
};
export default CardComponent;