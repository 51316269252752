import React, {useEffect, useState} from "react";
import PartnersComponent from "../partners/PartnersComponent";
import SectionComponent from "../sections/SectionComponent";
import {GetMenusApi} from "../../../app/home/apis/HomeApi";

const FooterComponent = () => {
    const [menus, setMenus] = useState([]);

    useEffect(() => {
        GetMenusApi("footer")
            .then(response => {
                setMenus(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }, []);


    return (
        <div>
            <div className={"container"}>
                <SectionComponent color={"#fff"}>
                    <PartnersComponent/>
                </SectionComponent>
            </div>
            <footer className="text-center text-lg-start text-muted" style={{backgroundColor: "#fff"}}>
                <section
                    className="d-flex justify-content-center justify-content-lg-between p-3 border-bottom"
                >
                </section>

                <section className="text-center">
                    <div className="container text-center text-md-start " >
                        <div className="row mt-5">
                            {menus.map((element, index) => (
                                <div key={element.id} className={index === 0 ? "col-md-4 col-lg-4 col-xl-4 mx-auto mb-4" : index === 4 ? "col-md-3 col-lg-3 col-xl-3 mx-auto mb-3" : "col-md-2 col-lg-2 col-xl-2 mx-auto mb-2"}>
                                    <h6 className="text-uppercase text-center fw-bold mb-4" style={{color: "#4adeff"}}>
                                        <b>{element.name}</b>
                                    </h6>
                                    {element.items_menu.map((element) => (
                                        element.url ?
                                            <a key={element.id} className="text-center" style={{color: "#000"}} href={element.url} target={"_blank"}><p  style={{color: "#707070"}} key={element.id} align={"center"}>{element.text}</p></a>
                                            :
                                            <p style={{color: "#707070"}} key={element.id} className="text-center" align={"center"}>{element.text}</p>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <div className="text-center p-4" style={{backgroundColor: "#fff"}}>
                    <p style={{color: "#000"}}>
                    &copy; {(new Date().getFullYear())} Copyright -
                    <a className="text-reset fw-bold" href="https://osgp.com/"> Observatorio de Salud de
                        Villavicencio</a></p>
                </div>
            </footer>
        </div>
    );
};
export default FooterComponent;