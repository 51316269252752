import * as React from 'react';
import {NavLink} from "react-router-dom";
import {api_url} from "../../../config/GlobalData";
import {useEffect, useState} from "react";
import {GetMenusApi} from "../../../app/home/apis/HomeApi";
import logoObservatory from '../../../assets/logos/Linea_Pag_Web-06_1.png';

let menuPrincipal = [
    {
        text: 'Inicio',
        url: ''
    },
    {
        text: 'Nosotros',
        url: 'nosotros'
    },
    {
        text: 'Dimensiones de Salud',
        url: 'dimensiones'
    },
    {
        text: 'Investigaciones',
        url: 'investigaciones'
    },
    {
        text: 'Publicaciones',
        url: 'publicaciones'
    },
    {
        text: 'Contacto',
        url: 'contacto'
    },
]

const HeaderComponent = () => {
    const [menus, setMenus] = useState([]);

    useEffect(() => {
        GetMenusApi("header")
            .then(response => {
                setMenus(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    let logo = menus.filter(menu => menu.slug === "logo").map((element) => (element.items_menu));
    const logoUrl = logo.length > 0 ? logo[0].length > 0 ? logo[0][0].image : logoObservatory : logoObservatory

    let menuP = menus.filter(menu => menu.slug === "menu-principal").map((element) => (element.items_menu));
    menuPrincipal = menuP.length > 0 ? menuP[0] : menuPrincipal

    return (
        <header>
            <nav className="navbar navbar-expand-lg" style={{backgroundColor: "#1162f3"}}>
                <div className="container-fluid">
                    <a className="navbar-brand me-2" href="/">
                        <img
                            src={logoUrl}
                            height="80px"
                            alt="Logo del Observatorio de Salud de Villavicencio"
                            loading="lazy"
                        />
                    </a>
                    <button
                        style={{color: "#fff"}}
                        className="navbar-toggler"
                        type="button"
                        data-mdb-toggle="collapse"
                        data-mdb-target="#navbarExample01"
                        aria-controls="navbarExample01"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i className="fas fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarExample01">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>

                        <div className="d-flex align-items-center">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                {menuPrincipal.map((page) => (
                                    <li key={page.id ? page.id : page.url} className="nav-item">
                                        <NavLink to={page.url ? page.url : ""} style={{marginRight: 30, color: "#fff"}}>
                                            {page.text}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                            <a href={api_url + "/api/admin/"} type="button"
                               className="btn btn-secondary btn-lg active" style={{backgroundColor: "#f44336"}}
                               role="button" aria-pressed="true">
                                Iniciar Sesión
                            </a>
                            {/*<button type="button" className="btn btn-primary me-3">
                                Registro
                            </button>*/}
                        </div>

                    </div>
                </div>
            </nav>
        </header>
    );
};
export default HeaderComponent;
