import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SectionComponent from "../../../shared/components/sections/SectionComponent";
import BreadCrumbComponent from "../../../shared/components/breadcrumb/BreadCrumbComponent";
import {GetIndicatorApi} from "../apis/IndicatorsApi";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";

export default function IndicatorView() {
    console.log("aloo")
    let params = useParams();
    const [indicator, setIndicator] = useState({});
    useEffect(() => {
        GetIndicatorApi(params['indicatorSlug'])
            .then(response => {
                setIndicator(response.data)
            })
            .catch(error => {
                console.log(error);
            })
    }, []);
    console.log(indicator);
    const sections = [
        {
            name: 'Inicio',
            slug: '/',
            active: false
        },
        {
            name: 'Dimensiones de salud',
            slug: '/dimensiones',
            active: false
        },
    ];

    return (
        <React.Fragment>
            {
                <div className={"container"}>
                    <SectionComponent title={indicator.name} color={"#fafeff"}>
                        <p style={{color: "#707070"}} align={"center"}>{indicator.description}</p>
                        <BreadCrumbComponent sections={sections}/>
                        <div align={"center"} dangerouslySetInnerHTML={{__html: indicator.dashboard}}/>
                        {
                            indicator.datasheet ?
                                <ButtonComponent className={"btn btn-success"} title={"Descargar Ficha Técnica"}
                                                 url={indicator.datasheet} target={"_blank"}/> : ""
                        }
                        {
                            indicator.dataset ?
                                <ButtonComponent className={"btn btn-success"} title={"Descargar Conjunto de Datos"}
                                                 url={indicator.dataset} target={"_blank"}/> : ""
                        }
                    </SectionComponent>
                    <SectionComponent color={"#fafeff"}>
                        <h3>Análisis</h3>
                        <div dangerouslySetInnerHTML={{__html: indicator.content}}/>
                    </SectionComponent>
                </div>
            }
        </React.Fragment>
    );
}