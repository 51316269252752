import React from "react";
import NotFoundComponent from "../../components/not-found/NotFoundComponent";

function NotFoundLayout() {
    return (
        <NotFoundComponent/>
    );
}

export default NotFoundLayout;
