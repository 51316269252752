import React, {useEffect, useState} from "react";
import SectionComponent from "../../../shared/components/sections/SectionComponent";
import CardCenterComponent from "../../../shared/components/cards/CardCenterComponent";
import BreadCrumbComponent from "../../../shared/components/breadcrumb/BreadCrumbComponent";
import {GetDimensionsApi} from "../apis/DimensionsApi";

export default function DimensionsView() {
    const [dimensions, setDimensions] = useState([]);
    useEffect(() => {
        GetDimensionsApi()
            .then(response => {
                setDimensions(response.data)
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    const sections = [
        {
            name: 'Inicio',
            slug: '/',
            active: false
        },
        {
            name: 'Dimensiones de salud',
            slug: '/dimensiones',
            active: true
        },
    ];

    return (

        <div className={"container"}>
            <SectionComponent title={"Dimensiones de Salud"}>
                <BreadCrumbComponent sections={sections}/>
                <div className="row">
                    {dimensions.map((dimension) => (
                        <div key={dimension.id} className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 mx-auto mb-3">
                            <CardCenterComponent
                                image={dimension.icon}
                                title={dimension.name}
                                description={dimension.description}
                                url={"dimensiones/" + dimension.slug}
                            />
                        </div>
                    ))}
                </div>
            </SectionComponent>
        </div>
    );
}