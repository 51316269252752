import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SectionComponent from "../../../shared/components/sections/SectionComponent";
import BreadCrumbComponent from "../../../shared/components/breadcrumb/BreadCrumbComponent";
import ImageComponent from "../../../shared/components/image/ImageComponent";
import {GetResearchApi} from "../apis/ResearchsApi";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";

export default function ResearchView() {
    let params = useParams();
    const [research, setResearch] = useState({});
    useEffect(() => {
        GetResearchApi(params['researchSlug'])
            .then(response => {
                setResearch(response.data)
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    const sections = [
        {
            name: 'Inicio',
            slug: '/',
            active: false
        },
        {
            name: 'Investigaciones',
            slug: '/investigaciones',
            active: false
        },
        {
            name: research.title,
            slug: research.slug,
            active: true
        },
    ];

    return (
        <React.Fragment>
            {
                <div className={"container"}>
                    <SectionComponent title={research.title}>
                        <BreadCrumbComponent sections={sections}/>
                        <h4>{research.category ? research.category.name : "Categoria"}</h4>
                        <p>Fecha de publicación: {research.created_at}</p>
                        <ImageComponent image={research.banner}/>
                    </SectionComponent>
                    <SectionComponent>
                        <div dangerouslySetInnerHTML={{__html: research.content}}/>
                        <br/>
                        {
                            research.file ?
                                <ButtonComponent className={"btn btn-success"} title={"Descargar Archivo"}
                                                 url={research.file} target={"_blank"}/> : ""
                        }
                    </SectionComponent>
                </div>
            }
        </React.Fragment>
    );
}
