
import React from "react";

const SectionComponent = (props) => {
    return (
        <section className={"content"} style={{backgroundColor: props.color}}>
            <h1 align={"center"} style={{marginTop: 30, marginBottom: 30, color: "#4adeff"}}>{props.title}</h1>
            {props.children}
            <br/>
        </section>
    );
};
export default SectionComponent;