import React from "react";
import {Outlet} from "react-router-dom";
import HeaderComponent from "../../components/header/HeaderComponent";
import FooterComponent from "../../components/footer/FooterComponent";
import ButtonFixedComponent from "../../components/dimensions/DimensionsButtonFixedComponent";

function BaseLayout() {

    return (
        <React.Fragment>
            <HeaderComponent/>
            <div style={{backgroundColor: "#fff"}}>
                <Outlet/>
            </div>
            <ButtonFixedComponent/>
            <FooterComponent/>
        </React.Fragment>
    );
}

export default BaseLayout;
