import {GetTokenApi, GetTokenRefreshApi} from "./AuthApi";
import TokenService from "./TokenService";

class AuthService {
    login(username, password) {
        return GetTokenApi({"username": username, "password": password})
            .then(response => {
                if (response.data.access) {
                    TokenService.setUser(response.data);
                }
                return response.data;
            })
            .catch(error => {
                console.log(error);
            })
    }

    refresh(refreshToken) {
        return GetTokenRefreshApi({
            refresh: refreshToken,
        }).then(response => {
            if (response.data.access) {
                TokenService.setUser(response.data);
            }
            return response.data;
        }).catch(error => {
            console.log(error);
            this.logout();
        });
    }

    logout() {
        TokenService.removeUser();
    }

    getCurrentUser() {
        return TokenService.getUser();
    }
}

export default new AuthService();