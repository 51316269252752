import React from "react";


const CardCenterComponent = (props) => {
    return (
        <center>
            <div className="card" style={{"backgroundColor": "#fff", height: 250, width: "100%"}} >
                <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                    <img src={props.image ? props.image : "https://www.artntech.com.co/images-osv/icon-1.png"}
                         alt={props.description}
                         className="img-fluid"/>
                    <a href={props.url}>
                        <div className="mask" style={{backgroundColor: "rgba(251, 251, 251, 0.15)"}}></div>
                    </a>
                </div>
                <div className="card-body">
                    <h5 className="card-title">{props.title ? props.title : "Título de la tarjeta"}</h5>
                    <p  style={{color: "#707070"}} align={"justify"} className="card-text">{props.description ? props.description.slice(0, 170) + " ..." : ""}</p>
                </div>
            </div>
        </center>
    );
};
export default CardCenterComponent;