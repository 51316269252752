import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import SectionComponent from "../../../shared/components/sections/SectionComponent";
import GridPostsComponent from "../../../shared/components/grids/GridPostsComponent";
import PaginationComponent from "../../../shared/components/pagination/PaginationComponent";
import BreadCrumbComponent from "../../../shared/components/breadcrumb/BreadCrumbComponent";
import {GetResearchesApi} from "../apis/ResearchsApi";

export default function ResearchesView() {
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        GetResearchesApi()
            .then(response => {
                setTotalPages(response.data['total_pages']);
                setCurrentPage(response.data['current']);
                setPosts(response.data['results']);
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    function handlePageClick(page) {
        GetResearchesApi(page)
            .then(response => {
                setTotalPages(response.data['total_pages']);
                setCurrentPage(response.data['current']);
                setPosts(response.data['results']);
            })
            .catch(error => {
                setPosts([])
                console.log(error);
            })
    }

    const sections = [
        {
            name: 'Inicio',
            slug: '/',
            active: false
        },
        {
            name: 'Investigaciones',
            slug: '/investigaciones',
            active: true
        },
    ];

    return (
        <div className={"container"}>
            <SectionComponent title={"Nuestras investigaciones"}>
                <BreadCrumbComponent sections={sections}/>
                <GridPostsComponent slug={"investigaciones"} cardsObject={posts}/>
                <PaginationComponent
                    onPageChange={handlePageClick}
                    currentPage={currentPage}
                    totalPages={totalPages}/>
                <Outlet/>
            </SectionComponent>
        </div>
    );
}
