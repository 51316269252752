import React from "react";
import ButtonComponent from "../button/ButtonComponent";


const AccordionComponent = (props) => {
    return (
        <div className="accordion" id="accordionExample">
            {
                props.listItems.length > 0
                    ? props.listItems.map(item => (
                        <div key={item.id} className="accordion-item">
                            <h2 className="accordion-header" id={"heading-" + item.id}>
                                <button
                                    className="accordion-button"
                                    type="button"
                                    data-mdb-toggle="collapse"
                                    data-mdb-target={"#collapse-" + item.id}
                                    aria-expanded="true"
                                    aria-controls={"collapse-" + item.id}
                                >
                                    {item.name ? item.name : "Item"}
                                </button>
                            </h2>
                            <div id={"collapse-" + item.id} className="accordion-collapse collapse"
                                 aria-labelledby={"heading-" + item.id}
                                 data-mdb-parent="#accordionExample">
                                <div style={{color: "#707070"}} className="accordion-body" align={"justify"}>
                                    {item.description ? item.description : ""}
                                    <br/>
                                    <ButtonComponent className={"btn btn-primary btn-sm"} title={"Ver indicador"}
                                                     url={props.slug + "/" + item.slug}/>
                                </div>
                            </div>
                        </div>
                    ))
                    : <p>No hay indicadores para esta dimensión.</p>
            }
        </div>
    );
};

export default AccordionComponent;