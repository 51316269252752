// Axios
import Axios from "../../../config/axiosConfig";

// Globals
import {api_url} from "../../../config/GlobalData";


export const GetPostsApi = (page = 1) => {

    return Axios.instance.get(`${api_url}/api/v1/portal/blog/?page=${page}&active=true`);

}

export const GetPostApi = (slug) => {

    return Axios.instance.get(`${api_url}/api/v1/portal/blog/${slug}`);

}
