import React, {useEffect, useState} from "react";
import AliceCarousel from "react-alice-carousel";
import 'react-alice-carousel/lib/alice-carousel.css';
import {GetDimensionsApi} from "../../../app/home/apis/HomeApi";

const handleDragStart = (e) => e.preventDefault();

const responsive = {
    0: { items: 3 },
    568: { items: 4 },
    1024: { items: 6 },
};


const DimensionsComponent = () => {
    const [dimensions, setDimensions] = useState([]);
    useEffect(() => {
        GetDimensionsApi()
            .then(response => {
                setDimensions(response.data)
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    const items = [];
    dimensions.forEach((dimension) => {
        items.push(
            <a href={"dimensiones/" + dimension.slug} data-mdb-toggle="tooltip" title={dimension.name}>
                <img src={dimension.icon} height={100} onDragStart={handleDragStart} alt={dimension.description}/>
                <p className="fs-5" style={{color: "#707070"}}>{dimension.name}</p>
            </a>
        );
    });

    return (
        <center>
            <AliceCarousel
                mouseTracking
                infinite={true}
                animationDuration={2000}
                autoPlay={true}
                autoPlayControls={false}
                autoPlayInterval={100}
                disableButtonsControls={true}
                disableDotsControls={false}
                responsive={responsive}
                controlsStrategy=""
                items={items}
            />
        </center>
    );
};
export default DimensionsComponent;