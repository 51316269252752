// Axios
import Axios from "../../../config/axiosConfig";

// Globals
import {api_url} from "../../../config/GlobalData";


export const GetDimensionsApi = () => {

    return Axios.instance.get(`${api_url}/api/v1/portal/dimensions/?active=true`);

}


export const GetDimensionApi = (slug) => {

    return Axios.instance.get(`${api_url}/api/v1/portal/dimensions/${slug}`);

}


export const GetIndicatorsFromDimensionApi = (slug) => {

    return Axios.instance.get(`${api_url}/api/v1/portal/dimensions/${slug}/indicators/?active=true`);

}
