import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ImageComponent from "../../../shared/components/image/ImageComponent";
import AccordionComponent from "../../../shared/components/accordion/AccordionComponent";
import SectionComponent from "../../../shared/components/sections/SectionComponent";
import BreadCrumbComponent from "../../../shared/components/breadcrumb/BreadCrumbComponent";
import {GetDimensionApi, GetIndicatorsFromDimensionApi} from "../apis/DimensionsApi";

export default function DimensionView() {
    let params = useParams();
    const [dimension, setDimension] = useState({});
    useEffect(() => {
        GetDimensionApi(params.dimensionSlug)
            .then(response => {
                setDimension(response.data)
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    const [indicators, setIndicators] = useState([]);
    useEffect(() => {
        GetIndicatorsFromDimensionApi(params.dimensionSlug)
            .then(response => {
                setIndicators(response.data)
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    const sections = [
        {
            name: 'Inicio',
            slug: '/',
            active: false
        },
        {
            name: 'Dimensiones de salud',
            slug: '/dimensiones',
            active: false
        },
        {
            name: dimension.name,
            slug: dimension.slug,
            active: true
        },
    ];

    return (
        <React.Fragment>
            {
                <div className={"container"}>
                    <SectionComponent title={dimension.name} color={"#fafeff"}>
                        <p align={"center"}>{dimension.description}</p>
                        <BreadCrumbComponent sections={sections}/>
                        <ImageComponent image={dimension.banner}/>
                    </SectionComponent>
                    <SectionComponent color={"#fafeff"}>
                        <div dangerouslySetInnerHTML={{__html: dimension.content}}/>
                        <h3>Indicadores de la dimensión</h3>
                        <AccordionComponent listItems={indicators} slug={params.dimensionSlug}/>
                    </SectionComponent>
                </div>
            }
        </React.Fragment>
    );
}