import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import SectionComponent from "../../../shared/components/sections/SectionComponent";
import GridPostsComponent from "../../../shared/components/grids/GridPostsComponent";
import PaginationComponent from "../../../shared/components/pagination/PaginationComponent";
import BreadCrumbComponent from "../../../shared/components/breadcrumb/BreadCrumbComponent";
import {GetPostsApi} from "../apis/PostsApi";

export default function PostsView() {
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        GetPostsApi()
            .then(response => {
                setTotalPages(response.data['total_pages']);
                setCurrentPage(response.data['current']);
                setPosts(response.data['results']);
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    function handlePageClick(page) {
        GetPostsApi(page)
            .then(response => {
                setTotalPages(response.data['total_pages']);
                setCurrentPage(response.data['current']);
                setPosts(response.data['results']);
            })
            .catch(error => {
                setPosts([])
                console.log(error);
            })
    }

    const sections = [
        {
            name: 'Inicio',
            slug: '/',
            active: false
        },
        {
            name: 'Publicaciones',
            slug: '/publicaciones',
            active: true
        },
    ];

    return (
        <div className={"container"}>
            <SectionComponent title={"Nuestras publicaciones"}>
                <BreadCrumbComponent sections={sections}/>
                <GridPostsComponent slug={"publicaciones"} cardsObject={posts}/>
                <PaginationComponent
                    onPageChange={handlePageClick}
                    currentPage={currentPage}
                    totalPages={totalPages}/>
                <Outlet/>
            </SectionComponent>
        </div>
    );
}