// Axios
import Axios from "../../../config/axiosConfig";

// Globals
import {api_url} from "../../../config/GlobalData";


export const GetIndicatorsApi = () => {

    return Axios.instance.get(`${api_url}/api/v1/portal/indicators-dashboards/?active=true`);

}


export const GetIndicatorApi = (slug) => {

    return Axios.instance.get(`${api_url}/api/v1/portal/indicators-dashboards/${slug}`);

}
