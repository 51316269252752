import React from "react";

import CardComponent from "../cards/CardComponent";

const GridPostsComponent = (props) => {
    return (
        <div>
            <div className="row mt-1">
                {props.cardsObject.map((cardObject) => (
                    <div key={cardObject.id}
                         className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4 justify-content-center">
                        <CardComponent
                            image={cardObject.image}
                            title={cardObject.title}
                            category={cardObject.category}
                            description={cardObject.description}
                            createdAt={cardObject.created_at}
                            url={props.slug + "/" + cardObject.slug}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
export default GridPostsComponent;