// Axios
import Axios from "../../../config/axiosConfig";

// Globals
import {api_url} from "../../../config/GlobalData";


export const GetResearchesApi = (page = 1) => {

    return Axios.instance.get(`${api_url}/api/v1/portal/research/?page=${page}&active=true`);

}

export const GetResearchApi = (slug) => {

    return Axios.instance.get(`${api_url}/api/v1/portal/research/${slug}`);

}
