import React from "react";

const ButtonFixedComponent = (props) => {
    return (
        <div>
            <div className="fixed-action-btn">
                <a href={"/dimensiones"} className="btn btn-floating btn-primary btn-lg" style={{backgroundColor: "#f44336"}}
                   data-mdb-toggle="tooltip" title={"Dimensiones"}>
                    <i className="fas fa-bars"/>
                </a>
                <ul className="list-unstyled">
                    <li>
                        <a className="btn-floating red"><i className="fas fa-star"/></a>
                    </li>
                    <li>
                        <a className="btn-floating yellow darken-1"><i className="fas fa-user"/></a>
                    </li>
                    <li>
                        <a className="btn-floating green"><i className="fas fa-envelope"/></a>
                    </li>
                    <li>
                        <a className="btn-floating blue"><i className="fas fa-shopping-cart"/></a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ButtonFixedComponent;