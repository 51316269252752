// Axios
import axios from 'axios';
import {setupCache} from 'axios-cache-adapter'
import TokenService from "../services/TokenService";
import AuthService from "../services/AuthService";
import {api_password, api_username} from "./GlobalData";

const cache = setupCache({
    maxAge: 2 * 60 * 1000,
    exclude: {
        // {Array} List of regular expressions to match against request URLs.
        paths: [
            /nosotros/,
            /publications/,
            /contacto/,
        ],
        // {Boolean} Exclude requests with query parameters.
        query: false,
        // {Function} Method which returns a `Boolean` to determine if request
        // should be excluded from cache.
        filter: ['post', 'patch', 'put', 'delete'],
        // {Array} HTTP methods which will be excluded from cache.
        // Defaults to `['post', 'patch', 'put', 'delete']`
        // Any methods listed will also trigger cache invalidation while using the default `config.invalidate` method.
        //
        // Note: the HEAD method is always excluded (hard coded).
        // the OPTIONS method is ignored by this library as it is automatically handled by browsers/clients to resolve cross-site request permissions
        //methods: ['post', 'patch', 'put', 'delete']
    },
})

const instance = axios.create({
    baseURL: 'http://127.0.0.1:8000',
    headers: {
        "Content-Type": "application/json",
    },
    //adapter: cache.adapter
});

instance.interceptors.request.use(function (config) {
    let token = TokenService.getLocalAccessToken();
    config.headers["Authorization"] = "Bearer " + token;
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (err.response && err.response.status === 401 &&
            originalConfig.url && !originalConfig.url.includes("/api/v1/users/token/refresh/") && !originalConfig._retry) {
            originalConfig._retry = true;
            try {
                const localTokenRefresh = TokenService.getLocalRefreshToken();
                console.log(localTokenRefresh);
                if (localTokenRefresh) {
                    await AuthService.refresh(localTokenRefresh);
                } else {
                    await AuthService.login(api_username, api_password);
                }
                return instance(originalConfig);
            } catch (_error) {
                return Promise.reject(_error);
            }
        }
        return Promise.reject(err);
    }
);


export default {instance};