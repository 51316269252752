import React, {useEffect, useState} from "react";
import SectionComponent from "../../../shared/components/sections/SectionComponent";
import CarouselComponent from "../../../shared/components/carousel/CarouselComponent";
import DimensionsComponent from "../../../shared/components/dimensions/DimensionsComponent";
import GridPostsComponent from "../../../shared/components/grids/GridPostsComponent";
import {GetBannersApi, GetLatestPostApi} from "../apis/HomeApi";

export default function HomeView() {
    const [banners, setBanners] = useState([]);
    useEffect(() => {
        GetBannersApi()
            .then(response => {
                setBanners(response.data)
            })
            .catch(error => {
                console.log(error);
            })
    }, []);


    const [latestPosts, setLatestPosts] = useState([]);
    useEffect(() => {
        GetLatestPostApi()
            .then(response => {
                setLatestPosts(response.data.results)
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    return (
        <div>
            <CarouselComponent elements={banners}/>
            <div className={"container"}>
                <SectionComponent title={"Dimensiones de Salud"} color={"#fff"}>
                    <DimensionsComponent/>
                </SectionComponent>
            </div>
            <div className={"container"}>
                <SectionComponent title={"Últimas publicaciones"} color={"#fff"}>
                    <GridPostsComponent cardsObject={latestPosts.slice(0, 8)} slug={"publicaciones"}/>
                </SectionComponent>
            </div>

        </div>
    );
}