import React, {useEffect, useState} from "react";
import AliceCarousel from "react-alice-carousel";
import 'react-alice-carousel/lib/alice-carousel.css';
import {GetTeamMembersApi} from "../../../app/about/apis/AboutApi";

const handleDragStart = (e) => e.preventDefault();

const responsive = {
    0: {items: 1},
    568: {items: 1},
    650: {items: 1},
    800: {items: 2},
    1024: {items: 3},
};


const TeamMembersComponent = (props) => {

    const [members, setTeamMembers] = useState([]);
    useEffect(() => {
        GetTeamMembersApi()
            .then(response => {
                setTeamMembers(response.data)
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    const items = [];
    members.forEach((member) => {
        items.push(
            <div className="card" onDragStart={handleDragStart} style={
                {
                    marginBottom: "20px", marginRight: "20px",
                    marginLeft: "20px", marginTop: "20px",
                    height: "600px",
                }
            }>
                <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                    <img src={member.image ? member.image : "https://mdbcdn.b-cdn.net/img/new/standard/nature/111.webp"}
                         style={{maxHeight: "100%", width: "100%"}}/>
                    <a href="#">
                        <div className="mask" style={{backgroundColor: "rgba(251, 251, 251, 0.15)"}}></div>
                    </a>
                </div>
                <div className="card-body">
                    <h4 className="card-title"><b>{member.full_name ? member.full_name : "Nombre Completo"}</b></h4>
                    <h6 className="card-title"><b>{member.profession ? member.profession.title : "Profesión"}</b></h6>
                    <p className="card-text"
                       align={"justify"}>{member.description ? member.description.slice(0, 200) + "..." : "Descripción del miembro del equipo de trabajo del observatorio"}</p>
                    {/*<a className="btn btn-primary" style={{backgroundColor: "#25d366", marginRight: "10px"}} href="#!" role="button">
                        <i className="fab fa-whatsapp"></i>
                    </a>*/}

                    {
                        member.cv_lac ?
                            <a className="btn btn-primary"
                               style={{backgroundColor: "#25d366", marginRight: "10px"}}
                               target={"_blank"}
                               href={member.linkedin} role="button">
                                <i className="fab fa-dribbble"></i>
                            </a>
                            :
                            null
                    }

                    {
                        member.linkedin ?
                            <a className="btn btn-primary"
                               style={{backgroundColor: "#0082ca", marginRight: "10px"}}
                               target={"_blank"}
                               href={member.linkedin} role="button">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            :
                            null
                    }

                    {
                        member.github ?
                            <a className="btn btn-primary"
                               style={{backgroundColor: "#333333", marginRight: "10px"}}
                               target={"_blank"}
                               href={member.github} role="button">
                                <i className="fab fa-github"></i>
                            </a>
                            :
                            null
                    }
                </div>
            </div>
        );
    });

    return (
        <div>
            <h2 style={{color: "#4adeff"}}>Equipo de trabajo</h2>
            <AliceCarousel
                infinite={true}
                animationType={"slide"}
                animationDuration={2000}
                autoPlay={true}
                autoPlayControls={false}
                autoPlayInterval={4000}
                disableButtonsControls={true}
                disableDotsControls={false}
                disableSlideInfo={false}
                responsive={responsive}
                controlsStrategy="default,alternate"
                keyboardNavigation={true}
                mouseTracking={true}
                touchTracking={true}
                items={items}
            />
        </div>
    );
};
export default TeamMembersComponent;