import React from "react";
import './shared/styles/App.css';
import BaseLayout from "./shared/layouts/base/BaseLayout";
import {Route, Routes} from "react-router-dom";
import HomeView from "./app/home/views/HomeView";
import AboutView from "./app/about/views/AboutView";
import DimensionsView from "./app/dimensions/views/DimensionsView";
import DimensionView from "./app/dimensions/views/DimensionView";
import NotFoundLayout from "./shared/layouts/base/NotFoundLayout";
import ContactView from "./app/contact/views/ContactView";
import PostsView from "./app/posts/views/PostsView";
import PostView from "./app/posts/views/PostView";
import IndicatorView from "./app/dimensions/views/IndicatorView";
import PageView from "./app/pages/views/PageView";
import ResearchesView from "./app/researches/views/ResearchesView";
import ResearchView from "./app/researches/views/ResearchView";

function App() {
    return (
        <Routes>
            <Route path="/" element={<BaseLayout/>}>
                <Route index element={<HomeView/>}/>
                <Route path="nosotros" element={<AboutView/>}/>
                <Route path="dimensiones" element={<DimensionsView/>}/>
                <Route path="dimensiones/:dimensionSlug" element={<DimensionView/>}/>
                <Route path="dimensiones/:dimensionSlug/:indicatorSlug" element={<IndicatorView/>}/>
                <Route path="publicaciones" element={<PostsView/>}/>
                <Route path="publicaciones/:postSlug" element={<PostView/>}/>
                <Route path="investigaciones" element={<ResearchesView/>}/>
                <Route path="investigaciones/:researchSlug" element={<ResearchView/>}/>
                <Route path="contacto" element={<ContactView/>}/>
                <Route path="paginas/:pageSlug" element={<PageView/>}/>
                <Route
                    path="*"
                    element={
                        <NotFoundLayout/>
                    }
                />
            </Route>
        </Routes>
    );
}

export default App;
