import React from "react";


const CardHorizontalComponent = (props) => {
    return (
        <div className="card mb-3">
            <div className="row g-0"  style={{backgroundColor: props.color}}>
                <div className="col-md-4">
                    <img
                        src={props.image ? props.image : "http://iefa.edu.co/wp-content/uploads/2021/04/mision.png"}
                        alt="Trendy Pants and Shoes"
                        className="img-fluid rounded-start"
                    />
                </div>
                <div className="col-md-8">
                    <div className="card-body">
                        <h5 align={"justify"} className="card-title">{props.title ? props.title : "Título de la tarjeta"}</h5>
                        <p align={"justify"} className="card-text"  style={{color: "#707070"}}>
                            {props.description ? props.description : "Descripción de la tarjeta"}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CardHorizontalComponent;