// Axios
import Axios from "../../../config/axiosConfig";

// Globals
import {api_url} from "../../../config/GlobalData";


export const GetPageApi = (slug) => {

    return Axios.instance.get(`${api_url}/api/v1/portal/pages/${slug}`);

}
