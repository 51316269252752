// Axios
import Axios from "../../../config/axiosConfig";

// Globals
import {api_url} from "../../../config/GlobalData";

export const GetBannersApi = () => {
    return Axios.instance.get(`${api_url}/api/v1/portal/principal-sliders/?active=true`);
}

export const GetDimensionsApi = () => {
    return Axios.instance.get(`${api_url}/api/v1/portal/dimensions/?active=true`);
}

export const GetLatestPostApi = () => {
    return Axios.instance.get(`${api_url}/api/v1/portal/blog/?active=true`);
}

export const GetPartnersApi = () => {
    return Axios.instance.get(`${api_url}/api/v1/portal/brands/?active=true`);
}

export const GetMenusApi = (section) => {
    return Axios.instance.get(`${api_url}/api/v1/portal/menus/?section=${section}&active=true`);
}