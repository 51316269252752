import React, {useEffect, useState} from "react";
import AliceCarousel from "react-alice-carousel";
import 'react-alice-carousel/lib/alice-carousel.css';
import {GetPartnersApi} from "../../../app/home/apis/HomeApi";

const handleDragStart = (e) => e.preventDefault();

const responsive = {
    0: {items: 1},
    568: {items: 1},
    650: {items: 1},
    800: {items: 2},
    1024: {items: 8},
};


const PartnersComponent = () => {

    const [partners, setPartners] = useState([]);
    useEffect(() => {
        GetPartnersApi()
            .then(response => {
                setPartners(response.data)
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    const items = [];
    partners.forEach((partner) => {
        items.push(
            <a href={partner.url}>
                <img src={partner.icon} height={100} onDragStart={handleDragStart} alt={partner.description}/>
            </a>
        );
    });

    return (
        <center>
            <AliceCarousel
                mouseTracking
                infinite={true}
                animationDuration={1000}
                autoPlay={true}
                autoPlayControls={false}
                autoPlayInterval={2000}
                disableButtonsControls={true}
                disableDotsControls={true}
                responsive={responsive}
                controlsStrategy="alternate"
                items={items}
            />
        </center>
    );
};
export default PartnersComponent;